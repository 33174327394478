import { CoreMenu } from '@core/types';
import { Role } from 'app/auth/models';

export const menu: CoreMenu[] = [
  // {
  //   id: 'home',
  //   title: 'Home',
  //   translate: 'MENU.HOME',
  //   type: 'item',
  //   icon: '',
  //   url: 'home'
  // },
  // {
  //   id: 'sample',
  //   title: 'Sample',
  //   translate: 'MENU.SAMPLE',
  //   type: 'item',
  //   icon: 'circle',
  //   url: 'sample'
  // },
  {
    id: 'contact',
    title: 'ผู้ติดต่อ',
    type: 'section',
    children: [
      {
        id: 'customer',
        title: 'ลูกค้า',
        type: 'item',
        icon: 'book',
        url: 'atms/customer',
      },
    ],
  },

  {
    id: 'filling-tax',
    title: 'ภาษี',
    type: 'section',
    role: [
      Role.Accountant,
      Role.AccountantSupervisor,
      Role.AccountantManager,
      Role.FinanceManager,
      Role.SuperAdmin,
    ],
    // hidden: ,
    children: [
      {
        id: 'filling-tax-report',
        role: [
          Role.AccountantSupervisor,
          Role.AccountantManager,
          Role.FinanceManager,
          Role.SuperAdmin,
        ],
        title: 'รายงานสำหรับยื่นภาษี',
        type: 'item',
        icon: 'book',
        url: 'atms/tax-report',
      },
      {
        id: 'filling-tax-report',
        role: [Role.Accountant],
        title: 'รายงานเพื่อสรุปภาษี',
        type: 'item',
        icon: 'book',
        url: 'atms/tax-report',
      },
    ],
  },

  {
    id: 'document',
    title: 'เอกสาร',
    type: 'section',
    children: [
      {
        id: 'myTask',
        title: 'งานของฉัน',
        type: 'item',
        icon: 'file-text',
        url: 'atms/my-task',
      },
      {
        id: 'createTask',
        title: 'สร้างเอกสาร',
        role: [
          Role.AccountantSupervisor,
          Role.AccountantManager,
          Role.Accountant,
          Role.SuperAdmin,
        ],
        type: 'item',
        icon: 'file-plus',
        url: 'atms/task-document',
      },
      {
        id: 'overTask',
        title: 'เกินกำหนดชำระ',
        type: 'item',
        icon: 'watch',
        role: [
          Role.AccountantSupervisor,
          Role.AccountantManager,
          Role.Accountant,
          Role.SuperAdmin,
        ],
        url: 'atms/task-overdue',
        badge: {
          title: '0',
          classes: 'badge-danger badge-pill',
        },
      },
      {
        id: 'documentHistory',
        title: 'ประวัติเอกสาร',
        type: 'item',
        icon: 'git-pull-request',
        url: 'atms/document-history',
      },
      {
        id: 'trashCan',
        title: 'ถังขยะ',
        type: 'item',
        icon: 'trash-2',
        role: [
          Role.Accountant,
          Role.AccountantSupervisor,
          Role.AccountantManager,
          Role.SuperAdmin,
        ],
        url: 'atms/trash-can',
      },
      {
        id: 'employee',
        title: 'พนักงาน',
        type: 'item',
        icon: 'users',
        role: [
          Role.AccountantSupervisor,
          Role.AccountantManager,
          Role.FinanceManager,
          Role.FinanceSupervisor,
          Role.SuperAdmin,
        ],
        url: 'atms/employee',
      },
      {
        id: 'report',
        title: 'รายงานย้อนหลัง',
        type: 'item',
        icon: 'grid',
        role: [
          Role.AccountantSupervisor,
          Role.AccountantManager,
          Role.FinanceManager,
          Role.FinanceSupervisor,
          Role.SuperAdmin,
        ],
        url: 'atms/report',
      },
      {
        id: 'user',
        title: 'ผู้ใช้',
        type: 'item',
        icon: 'user',
        url: 'atms/user',
      },
    ],
  },
  {
    id: 'admin',
    title: 'Admin',
    type: 'section',
    role: [Role.SuperAdmin],
    children: [
      {
        id: 'manageUser',
        title: 'จัดการผู้ใช้งาน',
        type: 'item',
        icon: 'user-plus',
        role: [Role.SuperAdmin],
        url: 'atms/manage-users',
      },
      {
        id: 'managePackage',
        title: 'จัดการค่าบริการ',
        type: 'item',
        icon: 'package',
        role: [Role.SuperAdmin],
        url: 'atms/manage-package',
      },
      {
        id: 'master-addr',
        title: 'จัดการข้อมูลที่อยู่',
        type: 'item',
        icon: 'map-pin',
        role: [Role.SuperAdmin],
        url: 'atms/master-address',
      },
      // {
      //   id: 'userGuide',
      //   title: 'คู่มือการใช้งาน',
      //   type: 'item',
      //   icon: 'grid',
      //   role: [Role.SuperAdmin],
      //   url: 'atms/user-guide',
      // },
    ],
  },
];
